@import 'theme';

.container {
    background-color: $offWhite;
    width: 75%;
    height: 90%;
    left: 12.5%;
    position: fixed;
    bottom: 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border: solid $offWhite;
    border-width: 5px;
}

.content { 
    margin: auto;
    width: 50%;
    text-align: center;
    color: $textColor;
}

.header { 
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
    padding-bottom: 40px;
}

.join-us {
    display: flex;
    padding-top: 10%;
    padding-left: 3%;
    width: 100%;
    height: 100%;
}

.ju-main {
    width: 100%;
    position: relative;
    padding-top: 12px;
    z-index: 0;
}

.ju-mailbox {
    width: 25%;
    height: 25%;
    position: relative;
    transform: translate(-55%, -5%); //, translateY(-5px);
    z-index: 10; 
}

.ju-text {
    width: 100%;
    font-size: 400%;
    color: $darkPrimary;
}

.ju-text-sub {
    font-size: 150%;
    color: $textColor;
}