@import '../../../../scss/theme.scss';

.email-container {
    display: flex;
    height: 50px;
    padding-top: 25px;
}

.email-input {
    width: 80%;
    font-size: 125%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 0;
    border-top: 1px solid !important;
    border-left: 1px solid !important;
    border-right: 1px solid !important;
    border-bottom: 1px solid !important;
}

.email-submit {
    width: 20%;
    position: relative;
    z-index: 10;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid !important;
    border-left: 1px solid !important;
    border-right: 1px solid !important;
    border-bottom: 1px solid !important;
}

.email-submit-icon {
    width: 100%;
    height: 40px;
}