@import 'theme';

// @font-face {
//     font-family: 'Open Sans';
//     font-style: normal;
//     font-weight: 400;
//     src: url('../fonts/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
//     src: local('Open Sans'), local('OpenSans'),
//          url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//          url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
//          url('../fonts/open-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//          url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
// }

body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue";
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    //     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    //     sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
    display: flex;
}

.container {
    background-color: $offWhite;
    width: 75%;
    height: 75%;
    left: 12.5%;
    position: fixed;
    bottom: 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border: solid $offWhite;
    border-width: 5px;
}

.container .emailInput {
    width: 60%;
}

.App-link {
    color: $darkPrimary;
}
